@import '../../styles/var';

.mpk-appbar-profile{
  .user-info{
    text-align:right;
    margin-right:16px;
    line-height: 14px;
    > div:first-child{
      font-size: $mpk-font-size-NS;
    }
    > div:last-child{
      font-size: $mpk-font-size-S;
      color:$mpk-font-color-D2;
      @if $mpk-appbar-theme == 'dark' {
        color:$mpk-font-color-L2;
      }
    }
  }

  .mpk-app-logo{
    // display:inherit !important;
  }

  .apps-shortcut{
    .md-list-tile{
      height:auto !important;
      position:relative;
    }
    .md-tile-addon.md-tile-addon--icon{
      height:auto !important;
      line-height: 0;
    }
    .md-fake-btn{
      display: flex;
      align-items: center;
      padding-top:8px;
      padding-bottom:8px;
    }
    .md-tile-content--left-icon{
      padding-left:16px !important;
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .mpk-appbar-profile{
    .user-info{
      display: none;
    }
  }
}