table-ssp {
  border-collapse: collapse;
  width: 100%;
}
table-ssp tr {
  margin: 0;
}
table-ssp tr td {
  font-size: 15px;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid black;
}
table-ssp tr.fk-clear {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
table-ssp tr.fk-clear td {
  border: none !important;
  padding: 0px 10px;
}
table-ssp td.center {
  text-align: center;
}
table-ssp td.right {
  text-align: right;
}