.mpk-sidebar-info .panel {
  background-color: rgba(0, 0, 0, 0.048);
  background-blend-mode: darken;
  background-color: rgba(255, 255, 255, 0.04);
  background-blend-mode: overlay;
  color: rgba(255, 255, 255, 0.92);
}
.mpk-sidebar-info .panel .header {
  height: 40px;
  color: #3F51B5;
  font-weight: 500;
}
.mpk-sidebar-info .panel .header .md-icon {
  font-size: 16px;
  cursor: pointer;
  margin-left: 4px;
}
.mpk-sidebar-info .panel .header {
  color: #ff8f00;
}
.mpk-sidebar-info .panel .md-icon {
  color: rgba(255, 255, 255, 0.92);
}
.mpk-sidebar-info .dialog {
  color: rgba(0, 0, 0, 0.72);
}
.mpk-sidebar-info .dialog .title {
  color: #c62828;
}