.mpk-appbar-profile .user-info {
  text-align: right;
  margin-right: 16px;
  line-height: 14px;
}
.mpk-appbar-profile .user-info > div:first-child {
  font-size: 12px;
}
.mpk-appbar-profile .user-info > div:last-child {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.72);
}
.mpk-appbar-profile .apps-shortcut .md-list-tile {
  height: auto !important;
  position: relative;
}
.mpk-appbar-profile .apps-shortcut .md-tile-addon.md-tile-addon--icon {
  height: auto !important;
  line-height: 0;
}
.mpk-appbar-profile .apps-shortcut .md-fake-btn {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mpk-appbar-profile .apps-shortcut .md-tile-content--left-icon {
  padding-left: 16px !important;
}

@media screen and (max-width: 600px) {
  .mpk-appbar-profile .user-info {
    display: none;
  }
}