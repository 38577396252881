.mpk-mitra-djp {
  background: white;
  border-radius: 4px;
  padding: 4px;
}
.mpk-mitra-djp.light {
  color: rgba(255, 255, 255, 0.92) !important;
}
.mpk-mitra-djp .body {
  line-height: 1;
  border-left: thin dotted rgba(0, 0, 0, 0.52) !important;
}
.mpk-mitra-djp .body * {
  color: rgba(0, 0, 0, 0.48) !important;
}
.mpk-mitra-djp .body .djp {
  letter-spacing: 1px;
}