.mpk-file-picker {
  width: 100%;
}
.mpk-file-picker #mpk-file-picker-input {
  display: none;
}
.mpk-file-picker img {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}
.mpk-file-picker .trigger {
  width: 100%;
  border: thin dashed rgba(0, 0, 0, 0.24);
  padding: 24px;
  color: rgba(0, 0, 0, 0.72);
  cursor: pointer;
}
.mpk-file-picker .trigger .file-info {
  margin-left: 16px;
}