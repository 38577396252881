
@import '../../styles/var';
$card-width: 240px;

.mpk-companies{
  h1{
    font-weight: 600;
    margin-top: 8px;
  }
  .company-center-container{
    margin: 0 auto;
    padding: 0 48px;
  }
  .lb{
    max-width: 240px;
    top: 8px;
  }
  .company-card{
    width: $card-width;
    display: inline-table;
    .company-card-logo{
      width: $card-width;
      height: $card-width;
      border-radius: 0;
      background: #836464;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .md-icon{
        font-size: 92px;
        color: $mpk-action-footer-color;
      }
    }
    .company-card-info{
      border-top: thin solid $mpk-action-footer-color;
      .md-icon{
        color: #bbb;
        font-size: 16px;
      }
    }
  }
}