.mpk-sidebar {
  z-index: 10;
  background-color: black;
  border-right: thin solid rgba(0, 0, 0, 0.064);
  width: 240px;
  display: inline-flex;
  color: rgba(0, 0, 0, 0.96);
  color: rgb(255, 255, 255);
}
.mpk-sidebar .mpk-mitra-djp * {
  color: rgba(0, 0, 0, 0.72) !important;
}
.mpk-sidebar.hide {
  display: none;
}

@media screen and (max-width: 768px) {
  .mpk-sidebar {
    position: absolute;
    left: 0;
    height: 100%;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.25);
    width: 240px;
    z-index: 1;
    display: inline-flex;
    left: 0;
    -webkit-animation: sidebar-show 0.5s; /* Safari 4+ */
    -moz-animation: sidebar-show 0.5s; /* Fx 5+ */
    -o-animation: sidebar-show 0.5s; /* Opera 12+ */
    animation: sidebar-show 0.5s; /* IE 10+, Fx 29+ */
  }
  .mpk-sidebar.hide {
    display: inline-flex;
    left: -240px;
    -webkit-animation: sidebar-hide 0.5s; /* Safari 4+ */
    -moz-animation: sidebar-hide 0.5s; /* Fx 5+ */
    -o-animation: sidebar-hide 0.5s; /* Opera 12+ */
    animation: sidebar-hide 0.5s; /* IE 10+, Fx 29+ */
  }
}
@-webkit-keyframes sidebar-show {
  from {
    left: -240px;
  }
  to {
    left: 0;
  }
}
@-moz-keyframes sidebar-show {
  from {
    left: -240px;
  }
  to {
    left: 0;
  }
}
@-o-keyframes sidebar-show {
  from {
    left: -240px;
  }
  to {
    left: 0;
  }
}
@keyframes sidebar-show {
  from {
    left: -240px;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes sidebar-hide {
  from {
    left: 0;
  }
  to {
    left: -240px;
  }
}
@-moz-keyframes sidebar-hide {
  from {
    left: 0;
  }
  to {
    left: -240px;
  }
}
@-o-keyframes sidebar-hide {
  from {
    left: 0;
  }
  to {
    left: -240px;
  }
}
@keyframes sidebar-hide {
  from {
    left: 0;
  }
  to {
    left: -240px;
  }
}