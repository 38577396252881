.mpk-panel {
  position: static;
}
.mpk-panel.small-header .header {
  font-size: 16px;
  border: none !important;
}
.mpk-panel .header {
  font-size: 18px;
  font-weight: 600;
  color: #3F51B5;
  position: relative;
  min-height: 60px;
  padding: 0 16px;
}
.mpk-panel .md-progress--linear {
  margin: 0;
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 3px;
  background: transparent;
}