.fk-preview .fk-header {
  text-align: center;
  font-weight: bold;
}
.fk-preview table {
  border-collapse: collapse;
  width: 100%;
}
.fk-preview table tr {
  margin: 0;
}
.fk-preview table tr td {
  font-size: 15px;
  padding: 5px 10px;
  margin: 0;
  border: 1px solid black;
}
.fk-preview table tr.fk-clear {
  border-right: 1px solid black;
  border-left: 1px solid black;
}
.fk-preview table tr.fk-clear td {
  border: none !important;
  padding: 0px 10px;
}
.fk-preview table td.center {
  text-align: center;
}
.fk-preview table td.right {
  text-align: right;
}
.fk-preview .ket-tambahan {
  padding: 1px;
  width: 190px;
  float: left;
  border: 1px solid black;
  text-align: center;
  font-size: 15px;
}
.fk-preview .penanda-tangan {
  width: 200px;
  float: right;
  text-align: center;
  font-size: 15px;
}
.fk-preview .penanda-tangan2 {
  width: 200px;
  float: left;
  text-align: center;
  font-size: 15px;
}