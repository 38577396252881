.mpk-card-article-thumbnail {
  margin-bottom: 16px;
}
.mpk-card-article-thumbnail .title {
  font-size: 20px;
  color: #3F51B5;
  margin-bottom: 8px;
  cursor: pointer;
}
.mpk-card-article-thumbnail .body {
  font-size: 14px;
  font-weight: 300;
}
.mpk-card-article-thumbnail .info {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.48);
}
.mpk-card-article-thumbnail .image {
  width: 240px;
  min-height: 160px;
}
.mpk-card-article-thumbnail .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mpk-card-article-thumbnail .action {
  margin-top: 16px;
}
.mpk-card-article-thumbnail.row .content {
  padding: 16px;
}
.mpk-card-article-thumbnail:last-child {
  margin-bottom: 0;
}
.mpk-card-article-thumbnail.column {
  height: auto;
}
.mpk-card-article-thumbnail.column .image {
  width: 100%;
  height: auto;
  min-height: 0;
  position: relative;
}
.mpk-card-article-thumbnail.column .image img {
  height: auto;
  object-fit: contain;
}
.mpk-card-article-thumbnail.column .content {
  padding: 16px;
}

@media screen and (max-width: 600px) {
  .mpk-card-article-thumbnail .image {
    width: 100%;
  }
}