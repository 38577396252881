.mpk-article {
  background: white;
}
.mpk-article > div {
  width: 692px;
  margin: 0 auto;
  position: relative;
}
.mpk-article .ills {
  padding-top: 16px;
}
.mpk-article .ills > * {
  width: 100%;
}
.mpk-article .content {
  padding: 16px 0;
}
.mpk-article .content .title {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
}
.mpk-article .content .info {
  margin-top: 8px;
}
.mpk-article .content .deck {
  font-size: 24px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.48);
  margin-top: 16px;
}
.mpk-article .content .body {
  margin-top: 16px;
  padding-bottom: 16px;
}

@media screen and (max-width: 692px) {
  .mpk-article > div {
    width: 100%;
  }
  .mpk-article .ills {
    padding-top: 0;
  }
  .mpk-article .content {
    padding: 24px;
  }
}