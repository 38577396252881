@import '../../styles/var';

.mpk-image-thumbnail{
  .image-container{
    width: 72px;
    height: 72px;
    position: relative;
    &.selected{
      border: 2px solid $mpk-primary-color;
    }
    .img{
      width: 100%;
      height: 100%;
      background: #d6d0c9;
      background-size: cover !important;
    }
    .md-icon{
      position: absolute;
      right: 4px;
      font-size: 14px;
      top: 4px;
      cursor: pointer;
      color: white;
      text-shadow: 2px 2px 2px rgba(0,0,0,.2);
    }
    .default-img{
      .md-icon{
        position: relative;
        cursor: default;
        top: unset;
        right: unset;
        text-shadow: unset;
        font-size: 48px;
      }
    }
  }
}
