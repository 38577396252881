@import '../../styles/var';

.mpk-hint{
	.content{
		padding: $mpk-padding-NS $mpk-padding-N;        
		background:$mpk-hint-color;
		color: $mpk-font-color-D2;
		.icon{
				color: #fbc205;
				margin-right: 8px;
				padding: 0;
		}
	}
}