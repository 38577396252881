@media screen and (max-width: 600px) {
  .mpk-container-transform {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    padding: 16px;
  }
  .mpk-container-transform > * {
    width: 100%;
  }
}