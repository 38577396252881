.mpk-commandbar {
  background: #fff9f6;
  height: 48px;
  padding: 0 16px;
  width: 100%;
  color: rgba(0, 0, 0, 0.72);
  flex: none;
}
.mpk-commandbar .actions {
  padding: 0 8px;
}
.mpk-commandbar .right-corner {
  padding-left: 16px;
  border-left: thin solid rgba(0, 0, 0, 0.064);
}
.mpk-commandbar .left-corner {
  padding-right: 16px;
  margin-right: 16px;
  border-right: thin solid rgba(0, 0, 0, 0.064);
}

.mpk-breadcrumb-item {
  font-weight: 300;
}
.mpk-breadcrumb-item:after {
  content: "|";
  padding: 0 8px;
}