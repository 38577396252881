.mpk-rate-plan-card-img {
  width: 100%;
  height: 180px;
}
.mpk-rate-plan-card-img .default {
  position: relative;
  width: 100%;
  height: 100%;
  background: #5d6570;
}
.mpk-rate-plan-card-img .default .md-icon {
  font-size: 72px;
  color: #aaa;
}
.mpk-rate-plan-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}