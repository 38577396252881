.mpk-text-field-mask {
  margin-top: 16px;
  position: relative;
}
.mpk-text-field-mask label {
  top: 0;
  transform: translate3d(0, 0, 0);
}
.mpk-text-field-mask .input-mask {
  margin-top: 24px;
  font-size: 14px;
  outline: none;
  padding: 8px;
}

@media screen and (min-width: 768px) {
  .mpk-text-field-mask .input-mask {
    font-size: 16px;
  }
}