@import '../../styles/var';

.mpk-notification{
  .badge{
    display: flex;
    width: 24px;
    height:24px;
    align-items: center;
    justify-content: center;
    font-size: $mpk-font-size-S;
    border-radius: 24px;
    margin:0 16px;
    font-weight: 500;
    color:$mpk-font-color-D3;
    border:thin solid $mpk-border-color-dark;
    &.active{
      background-color: $mpk-color-warn;
      color:white;
      cursor: pointer;
    }

    @if $mpk-appbar-theme == 'dark' {
      color:$mpk-font-color-L1;
      border-color:white;
    }
  }
}