.mpk-pagination {
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.mpk-pagination .info {
  padding: 0 16px;
}
.mpk-pagination .limit {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.mpk-pagination .limit > div:first-child {
  margin-right: 8px;
}
.mpk-pagination button.md-btn--icon .mdi {
  font-size: 24px;
}