.mpk-divider-label {
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #3F51B5;
  font-size: 12px;
  font-weight: 600;
  color: #3F51B5;
}
.mpk-divider-label:first-child {
  padding-top: 0;
  margin-top: 0;
}