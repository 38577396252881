@import '~izitoast/dist/css/iziToast.min.css';
/*LAYOUT*/
.mpk-layout {
  display: flex;
  justify-content: flex-start;
}
.mpk-layout.column {
  flex-direction: column;
}
.mpk-layout.column > .flex {
  min-height: 1px;
}
.mpk-layout.justify-center {
  justify-content: center;
}
.mpk-layout.justify-arround {
  justify-content: space-around;
}
.mpk-layout.justify-right, .mpk-layout.justify-end {
  justify-content: flex-end;
}
.mpk-layout.justify-between {
  justify-content: space-between;
}
.mpk-layout.align-center {
  align-items: center;
}
.mpk-layout.align-start {
  align-items: flex-start;
}
.mpk-layout.wrap {
  flex-wrap: wrap;
}
.mpk-layout.fill {
  width: 100%;
  height: 100%;
}
.mpk-layout > .flex {
  flex: 1;
}
.mpk-layout > .flex-none {
  flex: none;
}
.mpk-layout > * {
  min-width: 0px;
  min-height: 0px;
}

.mpk-flex {
  flex: 1;
}

.mpk-flex-none {
  flex: none;
}

.mpk-layout-fill {
  width: 100%;
  height: 100%;
}

.mpk-full.width {
  width: 100%;
}
.mpk-full.height {
  height: 100%;
}
.mpk-full.viewport-width {
  width: 100vw;
}
.mpk-full.viewport-height {
  height: 100vh;
}

.mpk-side-panel {
  width: 240px;
}

.mpk-align-center {
  text-align: center !important;
}

.mpk-align-right {
  text-align: right !important;
}

.mpk-align-left {
  text-align: left !important;
}

.mpk-content {
  overflow: auto;
  height: 100%;
}

/*COLOR*/
.mpk-bg-color-L1 {
  background-color: #fafafa;
}

.mpk-bg-color-L2 {
  background-color: #f8f8f8;
}

.mpk-bg-color-L3 {
  background-color: #f2f2f2;
}

.mpk-bg-color-D1 {
  background-color: #263238;
}

.mpk-bg-color-G1 {
  background-color: #fff9f6 !important;
}

.mpk-font-color-alert {
  color: #ff8f00;
}

.mpk-font-color-idle {
  color: #9e9e9e;
}

.mpk-font-color-success {
  color: #1565c0;
}

.mpk-font-color-warn {
  color: #c62828;
}

.mpk-font-color-progress {
  color: #1565c0;
}

.mpk-font-color-L1 {
  color: rgb(255, 255, 255) !important;
}

.mpk-font-color-L2 {
  color: rgba(255, 255, 255, 0.92) !important;
}

.mpk-font-color-L3 {
  color: rgba(255, 255, 255, 0.54) !important;
}

.mpk-font-color-D1 {
  color: rgba(0, 0, 0, 0.96) !important;
}

.mpk-font-color-D2 {
  color: rgba(0, 0, 0, 0.72) !important;
}

.mpk-font-color-D3 {
  color: rgba(0, 0, 0, 0.48) !important;
}

.mpk-font-color-primary {
  color: #3F51B5 !important;
}

.mpk-font-color-secondary {
  color: #ff6e40 !important;
}

.mpk-font-size-XS {
  font-size: 8px !important;
}

.mpk-font-size-S {
  font-size: 10px !important;
}

.mpk-font-size-NS {
  font-size: 12px !important;
}

.mpk-font-size-N {
  font-size: 14px !important;
}

.mpk-font-size-M {
  font-size: 16px !important;
}

.mpk-font-size-L {
  font-size: 18px !important;
}

.mpk-font-size-XL {
  font-size: 20px !important;
}

.mpk-font-size-XXL {
  font-size: 24px !important;
}

/*FONT WEIGHT*/
.mpk-font-weight-T {
  font-weight: 200;
}

.mpk-font-weight-L {
  font-weight: 300;
}

.mpk-font-weight-N {
  font-weight: 400;
}

.mpk-font-weight-M {
  font-weight: 500;
}

.mpk-font-weight-B {
  font-weight: 600;
}

.mpk-font-weight-EB {
  font-weight: 800;
}

/*FONT FAMILY*/
.mpk-font-family-Main {
  font-family: "Roboto", sans-serif;
}

.mpk-font-family-Body {
  font-family: "Open Sans", sans-serif;
}

/*BORDER*/
.mpk-border.solid {
  border-style: solid !important;
  border-width: 0;
}

.mpk-border.dashed {
  border-style: dashed !important;
  border-width: 0;
}

.mpk-border.dotted {
  border-style: dotted !important;
  border-width: 0;
}

.mpk-border.light {
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-width: 0;
}

.mpk-border.dark {
  border-color: rgba(0, 0, 0, 0.064) !important;
  border-width: 0;
}

.mpk-border.all, .mpk-border.border-all {
  border-width: thin;
}
.mpk-border.top, .mpk-border.border-top {
  border-top-width: thin;
}
.mpk-border.bottom, .mpk-border.border-bottom {
  border-bottom-width: thin;
}
.mpk-border.left, .mpk-border.border-left {
  border-left-width: thin;
}
.mpk-border.right, .mpk-border.border-right {
  border-right-width: thin;
}

/*PADDING*/
.mpk-padding-S.all, .mpk-padding-S.padding-all {
  padding: 8px;
}

.mpk-padding-NS.all, .mpk-padding-NS.padding-all {
  padding: 12px;
}

.mpk-padding-N.all, .mpk-padding-N.padding-all {
  padding: 16px;
}

.mpk-padding-M.all, .mpk-padding-M.padding-all {
  padding: 24px;
}

.mpk-padding-L.all, .mpk-padding-L.padding-all {
  padding: 48px;
}

.mpk-padding-XL.all, .mpk-padding-XL.padding-all {
  padding: 56px;
}

.mpk-padding-S.left, .mpk-padding-S.padding-left {
  padding-left: 8px;
}

.mpk-padding-NS.left, .mpk-padding-NS.padding-left {
  padding-left: 12px;
}

.mpk-padding-N.left, .mpk-padding-N.padding-left {
  padding-left: 16px;
}

.mpk-padding-M.left, .mpk-padding-M.padding-left {
  padding-left: 24px;
}

.mpk-padding-L.left, .mpk-padding-L.padding-left {
  padding-left: 48px;
}

.mpk-padding-XL.left, .mpk-padding-XL.padding-left {
  padding-left: 56px;
}

.mpk-padding-S.right, .mpk-padding-S.padding-right {
  padding-right: 8px;
}

.mpk-padding-NS.right, .mpk-padding-NS.padding-right {
  padding-right: 12px;
}

.mpk-padding-N.right, .mpk-padding-N.padding-right {
  padding-right: 16px;
}

.mpk-padding-M.right, .mpk-padding-M.padding-right {
  padding-right: 24px;
}

.mpk-padding-L.right, .mpk-padding-L.padding-right {
  padding-right: 48px;
}

.mpk-padding-XL.right, .mpk-padding-XL.padding-right {
  padding-right: 56px;
}

.mpk-padding-S.top, .mpk-padding-S.padding-top {
  padding-top: 8px;
}

.mpk-padding-NS.top, .mpk-padding-NS.padding-top {
  padding-top: 12px;
}

.mpk-padding-N.top, .mpk-padding-N.padding-top {
  padding-top: 16px;
}

.mpk-padding-M.top, .mpk-padding-M.padding-top {
  padding-top: 24px;
}

.mpk-padding-L.top, .mpk-padding-L.padding-top {
  padding-top: 48px;
}

.mpk-padding-XL.top, .mpk-padding-XL.padding-top {
  padding-top: 56px;
}

.mpk-padding-S.bottom, .mpk-padding-S.padding-bottom {
  padding-bottom: 8px;
}

.mpk-padding-NS.bottom, .mpk-padding-NS.padding-bottom {
  padding-bottom: 12px;
}

.mpk-padding-N.bottom, .mpk-padding-N.padding-bottom {
  padding-bottom: 16px;
}

.mpk-padding-M.bottom, .mpk-padding-M.padding-bottom {
  padding-bottom: 24px;
}

.mpk-padding-L.bottom, .mpk-padding-L.padding-bottom {
  padding-bottom: 48px;
}

.mpk-padding-XL.bottom, .mpk-padding-XL.padding-bottom {
  padding-bottom: 56px;
}

/*MARGIN*/
.mpk-margin-S.all, .mpk-margin-S.margin-all {
  margin: 8px;
}

.mpk-margin-NS.all, .mpk-margin-NS.margin-all {
  margin: 12px;
}

.mpk-margin-N.all, .mpk-margin-N.margin-all {
  margin: 16px;
}

.mpk-margin-M.all, .mpk-margin-M.margin-all {
  margin: 24px;
}

.mpk-margin-L.all, .mpk-margin-L.margin-all {
  margin: 48px;
}

.mpk-margin-XL.all, .mpk-margin-XL.margin-all {
  margin: 56px;
}

.mpk-margin-S.left, .mpk-margin-S.margin-left {
  margin-left: 8px;
}

.mpk-margin-NS.left, .mpk-margin-NS.margin-left {
  margin-left: 12px;
}

.mpk-margin-N.left, .mpk-margin-N.margin-left {
  margin-left: 16px;
}

.mpk-margin-M.left, .mpk-margin-M.margin-left {
  margin-left: 24px;
}

.mpk-margin-L.left, .mpk-margin-L.margin-left {
  margin-left: 48px;
}

.mpk-margin-XL.left, .mpk-margin-XL.margin-left {
  margin-left: 56px;
}

.mpk-margin-S.right, .mpk-margin-S.margin-right {
  margin-right: 8px;
}

.mpk-margin-NS.right, .mpk-margin-NS.margin-right {
  margin-right: 12px;
}

.mpk-margin-N.right, .mpk-margin-N.margin-right {
  margin-right: 16px;
}

.mpk-margin-M.right, .mpk-margin-M.margin-right {
  margin-right: 24px;
}

.mpk-margin-L.right, .mpk-margin-L.margin-right {
  margin-right: 48px;
}

.mpk-margin-XL.right, .mpk-margin-XL.margin-right {
  margin-right: 56px;
}

.mpk-margin-S.top, .mpk-margin-S.margin-top {
  margin-top: 8px;
}

.mpk-margin-NS.top, .mpk-margin-NS.margin-top {
  margin-top: 12px;
}

.mpk-margin-N.top, .mpk-margin-N.margin-top {
  margin-top: 16px;
}

.mpk-margin-M.top, .mpk-margin-M.margin-top {
  margin-top: 24px;
}

.mpk-margin-L.top, .mpk-margin-L.margin-top {
  margin-top: 48px;
}

.mpk-margin-XL.top, .mpk-margin-XL.margin-top {
  margin-top: 56px;
}

.mpk-margin-S.bottom, .mpk-margin-S.margin-bottom {
  margin-bottom: 8px;
}

.mpk-margin-NS.bottom, .mpk-margin-NS.margin-bottom {
  margin-bottom: 12px;
}

.mpk-margin-N.bottom, .mpk-margin-N.margin-bottom {
  margin-bottom: 16px;
}

.mpk-margin-M.bottom, .mpk-margin-M.margin-bottom {
  margin-bottom: 24px;
}

.mpk-margin-L.bottom, .mpk-margin-L.margin-bottom {
  margin-bottom: 48px;
}

.mpk-margin-XL.bottom, .mpk-margin-XL.margin-bottom {
  margin-bottom: 56px;
}

/*ALLIGN*/
.mpk-align-center {
  text-align: center;
}

.mpk-align-right {
  text-align: right;
}

/*POINTER*/
.mpk-cursor-pointer {
  cursor: pointer;
}

/*STATUS*/
.mpk-status {
  color: white;
  font-size: 10px;
  padding: 4px;
  border-radius: 2px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}
.mpk-status.clickable {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.mpk-status.clickable:active {
  box-shadow: none;
}
.mpk-status.success {
  background-color: #1565c0;
}
.mpk-status.success.outline {
  border: 2px solid #1565c0;
  color: #1565c0;
  background-color: transparent;
}
.mpk-status.warn {
  background-color: #c62828;
}
.mpk-status.warn.outline {
  border: 2px solid #c62828;
  color: #c62828;
  background-color: transparent;
}
.mpk-status.progress {
  background-color: #1565c0;
}
.mpk-status.progress.outline {
  border: 2px solid #1565c0;
  color: #1565c0;
  background-color: transparent;
}
.mpk-status.alert {
  background-color: #ff8f00;
}
.mpk-status.alert.outline {
  border: 2px solid #ff8f00;
  color: #ff8f00;
  background-color: transparent;
}
.mpk-status.idle {
  background-color: #9e9e9e;
}
.mpk-status.idle.outline {
  border: 2px solid #9e9e9e;
  color: #9e9e9e;
  background-color: transparent;
}

.mpk-relative-position {
  position: relative;
}

.mpk-position.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
}
.mpk-position.relative {
  position: relative;
}
.mpk-position.fixed {
  position: fixed;
}
.mpk-position.absolute {
  position: absolute;
}

/*ANIMATION*/
.mpk-animation.slide-in {
  animation: slide-in 0.5s;
  animation-direction: alternate;
}
.mpk-animation.slide-in-down {
  animation: slide-in-down 0.2s ease-out;
  animation-direction: alternate;
}
.mpk-animation.slide-left {
  animation: slide-left 0.4s ease-out;
  animation-direction: alternate;
}
.mpk-animation.slide-right {
  animation: slide-right 0.4s ease-out;
  animation-direction: alternate;
}

@keyframes slide-in {
  0% {
    transform: translateY(48px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-down {
  0% {
    transform: translateY(-48px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/*OVERIDE*/
button span {
  font-weight: 400 !important;
}

button.dark {
  background: #3a3a3a !important;
}
button.md-btn--icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}
button.md-btn--icon .mdi {
  font-size: 20px;
}

.md-btn--text {
  min-width: 1px !important;
}

thead .md-table-row, tbody .md-table-row:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.052) !important;
}

.md-progress--linear {
  background: rgba(0, 0, 0, 0.25) !important;
}

.md-divider--text-field:after {
  height: 1px !important;
}

.md-paper {
  background: white;
}

.md-table-column__fixed .md-table-checkbox--header {
  align-items: center;
}

.md-list-item .md-icon {
  font-size: 18px;
}

.md-text-field-container--input-block input {
  margin-top: unset;
}

/*LINK*/
.mpk-link {
  color: #2883e5;
  cursor: pointer !important;
}
.mpk-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.mpk-link-on-hover {
  cursor: pointer;
}
.mpk-link-on-hover:hover {
  color: #2883e5 !important;
}

/*BODY*/
.mpk-body {
  font-size: 16px;
  line-height: 24px;
}

/*AUTO SIZE*/
.mpk-auto-width {
  width: auto !important;
}

.mpk-auto-height {
  height: auto !important;
}

.iziToast-wrapper-bottomCenter {
  left: unset !important;
  text-align: right;
}

.md-dialog-footer {
  border-top: thin solid rgba(0, 0, 0, 0.064);
  background: #f7efe8;
}

.md-panel-column {
  color: #3F51B5 !important;
  font-weight: 600;
}

.md-expansion-panel--expanded:not(:first-child) {
  margin-top: 0 !important;
}

.mpk-dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.mpk-container {
  width: 1024px;
  margin: 0 auto;
}

.mpk-hide {
  display: none !important;
}

.mpk-hide-sm {
  display: inherit !important;
}

.mpk-hide-md {
  display: inherit !important;
}

.mpk-hide-xs {
  display: inherit !important;
}

.mpk-show {
  display: inherit !important;
}

.mpk-show-sm {
  display: none !important;
}

.mpk-show-md {
  display: none !important;
}

.mpk-show-xs {
  display: none !important;
}

.mpk-no-copy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.mpk-no-wrap {
  white-space: nowrap;
}

/*MEDIA*/
@media screen and (max-width: 960px) {
  .mpk-layout.column-sm {
    flex-direction: column;
  }
  .mpk-layout.column-sm.child-fill > * {
    width: 100%;
  }
  .mpk-layout.column-sm.reverse {
    flex-direction: column-reverse;
  }
  .mpk-layout.row-sm {
    flex-direction: row;
  }
  .mpk-layout.row-sm.reverse {
    flex-direction: row-reverse;
  }
  .mpk-layout.justify-center-sm {
    justify-content: center;
  }
  .mpk-layout.justify-arround-sm {
    justify-content: space-around;
  }
  .mpk-layout.justify-right-sm {
    justify-content: flex-end;
  }
  .mpk-layout.justify-between-sm {
    justify-content: space-between;
  }
  .mpk-hide-sm {
    display: none !important;
  }
  .mpk-show-sm {
    display: unset !important;
  }
  .mpk-position.relative-sm {
    position: relative;
  }
  .mpk-position.sticky-sm {
    position: sticky;
    top: 0;
  }
  .mpk-position.fixed-sm {
    position: fixed;
  }
}
@media screen and (max-width: 600px) {
  .mpk-layout.column-xs {
    flex-direction: column;
  }
  .mpk-layout.column-xs.child-fill > * {
    width: 100%;
  }
  .mpk-layout.column-xs.reverse {
    flex-direction: column-reverse;
  }
  .mpk-layout.row-xs {
    flex-direction: row;
  }
  .mpk-layout.row-xs.reverse {
    flex-direction: row-reverse;
  }
  .mpk-layout.justify-center-xs {
    justify-content: center;
  }
  .mpk-layout.justify-arround-xs {
    justify-content: space-around;
  }
  .mpk-layout.justify-right-xs {
    justify-content: flex-end;
  }
  .mpk-layout.justify-between-xs {
    justify-content: space-between;
  }
  .mpk-hide-xs {
    display: none !important;
  }
  .mpk-show-xs {
    display: unset !important;
  }
  .mpk-position.relative-xs {
    position: relative;
  }
  .mpk-position.sticky-xs {
    position: sticky;
    top: 0;
  }
  .mpk-position.fixed-xs {
    position: fixed;
  }
}
.ag-header-cell {
  background: #F5F7F7;
}

.actions-button-cell {
  overflow: visible;
}

.ag-theme-balham .ag-cell {
  line-height: 30px !important;
}

.md-data-table .md-table-row tr, .md-data-table .md-table-row td {
  border: thin solid rgba(0, 0, 0, 0.052) !important;
  padding: 0 5px;
}

.md-dialog-container.md-overlay {
  z-index: 50 !important;
}

.md-table-header .md-table-checkbox .md-icon {
  margin-top: 5px !important;
  margin-left: 5px !important;
  font-size: 12px !important;
}

.md-table-body .md-table-checkbox .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body .md-table-checkbox .md-btn--icon {
  padding: 12px !important;
  height: 0px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle > .md-icon > .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle > .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle.md-btn--icon {
  padding: 12px !important;
  height: 0px !important;
}

.md-data-table--plain .md-table-column--plain {
  font-size: 12px !important;
}

.md-data-table--plain .md-table-column--plain {
  font-size: 12px !important;
  height: 20px !important;
}

@media screen and (min-width: 1025px) {
  .md-table-body .md-selection-control-container {
    height: 10px !important;
    margin-top: -20px !important;
  }
}
window, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

.srd-diagram {
  width: 100%;
  min-height: 400px;
}

.mpk-bluepring-bg {
  background: url("./assets/bluepring-bg.jpg");
}

#mpk-inner-tbl > div {
  height: 100%;
}

.md-table-body-setting .md-grid {
  margin: 0 !important;
}

.center-container {
  width: 1024px;
  margin: 0 auto;
}

.side-container {
  width: 240px;
}

.text-editor {
  border: thin solid rgba(0, 0, 0, 0.064);
  padding: 0 16px;
}

.mpk-tab-clear .md-tab--inactive {
  color: rgba(0, 0, 0, 0.72) !important;
}

.mpk-tab-flex .md-tabs-content {
  flex: 1;
}
.mpk-tab-flex .md-tabs-content .react-swipeable-view-container, .mpk-tab-flex .md-tabs-content .react-swipeable-view-container > div {
  height: 100% !important;
}

.md-dark-theme .md-text {
  color: rgba(255, 255, 255, 0.87);
}
.md-dark-theme .md-text--secondary {
  color: rgba(255, 255, 255, 0.54);
}

.form .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-menu--select-field .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-list--menu-contained.md-paper {
  width: 100% !important;
}

.md-text-field-container .md-btn--icon {
  position: absolute !important;
  height: 20px;
  width: 20px;
  color: inherit;
  top: 30px;
}

.mpk-expansion-no-padding .md-panel-content {
  padding: 0 0px 16px;
}

.module-menu {
  position: absolute !important;
  top: -20px;
  left: 170px;
  z-index: 2;
  background: white !important;
}

.DayPicker-NavBar {
  z-index: 1000;
}

.mpk-tab-white .md-tab {
  color: rgba(0, 0, 0, 0.72);
}
.mpk-tab-white .md-tab--inactive {
  color: rgba(0, 0, 0, 0.5);
}

.mpk-progress {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .center-container {
    width: 100%;
    padding: 0 16px;
  }
}
@media screen and (min-width: 1025px) {
  .md-tab {
    min-width: 72px !important;
  }
}
.ebillingCardTable > div {
  height: 550px;
}