@import '../../styles/var';

.mpk-dialog-container{
  position: relative;
  color:$mpk-font-color-D2;
  .md-progress--linear{
    position: absolute;
    top:0 !important;
    left:0;
    margin:0;
    background-color: $mpk-grey-2 !important;
  }
}

.mpk-dialog{
  position:relative;
  color:$mpk-font-color-D2;
  .md-progress--linear{
    position: absolute;
    top:0 !important;
    left:0;
    margin:0;
    background-color: $mpk-grey-2 !important;
  }
  .md-btn--dialog{
    padding: 8px 16px;
  }
  .error-block{
    padding:0 24px;
    > div {
      padding: 8px 8px;
      display: flex;
      align-items: center;
      color: $mpk-color-warn;
      font-weight: 600;
      margin-bottom: $mpk-padding-N;
      border: 2px solid $mpk-color-warn;
      .md-icon {
        margin-right: 8px;
        color: $mpk-color-warn;
      }
    }
  }
  .md-dialog-footer{
    background: $mpk-action-footer-color;
    border-top:thin solid $mpk-border-color-dark;
  }
  .md-dialog{
    min-width: 280px;
    width: auto;
  }
  &.dialog-form {
    > .md-paper {
      display: flex;
      flex-direction: column;
    }
    .md-dialog-content {
      flex: 1;
      overflow: auto;
      .form {
        padding: 0 24px;
      }
    }
    .md-dialog-content--padded {
      padding: 0;
    }
    &.size-large {
      .md-paper {
        width: 80%;
        height: 80%;
      }
    }
    &.size-medium {
      .md-paper {
        width: $mpk-media-sm;
        height: 80%;
      }
    }
    &.size-normal {
      .md-paper {
        width: $mpk-media-xs;
        height: 80%;
      }
    }
    &.size-small {
      .md-paper {
        width: 480px;
        height: 80%;
      }
    }
    &.relative-content {
      .md-paper {
        display: inline-block;
        height: auto;
      }
    }
  }
}

.md-overlay{
  width: 100% !important;
}

@media screen and (max-width: $mpk-media-sm){
  .mpk-dialog{
    &.size-medium, &.size-normal{
      .md-paper{
        width: 100%;
        height:100%;
      }
    }
  }
}