@import '~mdi/css/materialdesignicons.css';
@import '~material-icons/css/material-icons.css';
@import '~react-quill/dist/quill.snow.css';
@import './assets/animate.css';
@import 'globals';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');

@include react-md-everything;

$default-font:'Source Sans Pro', sans-serif;
$decorative-font:'Roboto Slab', serif;
$body-font:'Open Sans', serif !important;

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $default-font;
  background-color: #eee;
}

h1, h2, h3, h4, h5, h6{
  font-family: $default-font;
}




#root{
  width: 100%;
  height: 100%;
}

.decorative{
  font-family: $decorative-font !important;
}

.body-text{
  font-family: $body-font;
  font-weight: 400;
  > * {
    font-family: $body-font;
    font-weight: 400;
  }
}

.mpk-layout > * {
  min-width: 0px;
  min-height: 0px;
}

.iziToast {

  border-radius: none !important;
  &:after {
    border-radius: none !important;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4) !important;
  }
}

.md-dialog-container {
  z-index: 1000 !important;
}

// .mpk-appbar {
//   z-index: 0 !important;
// }
