.mpk-relative-grid {
  column-gap: 16px;
  margin: 16px auto;
}
.mpk-relative-grid .figure {
  transition: opacity 0.4s ease-in-out;
  margin-top: 2px;
  margin-bottom: 16px;
  display: inline-block;
  column-break-inside: avoid;
}