.mpk-bg-slice {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
  bottom: 0;
  background: #3F51B5;
}
.mpk-bg-slice.over-height {
  height: 140%;
}
.mpk-bg-slice .parallelogram-1 {
  position: absolute;
  top: 0;
  left: 20%;
  width: 70%;
  height: 100%;
  opacity: 0.7;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #3F51B5;
  background: -moz-linear-gradient(top, #3F51B5 56%, #3F51B5 100%);
  background: -webkit-linear-gradient(top, #3F51B5 56%, #3F51B5 100%);
  background: linear-gradient(to bottom, #3F51B5 56%, #3F51B5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$mpk-gradient-primary-1", endColorstr="$mpk-gradient-primary-2",GradientType=0 );
}
.mpk-bg-slice .parallelogram-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 0.6;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #3F51B5;
  background: -moz-linear-gradient(top, #3F51B5 0%, #3F51B5 24%);
  background: -webkit-linear-gradient(top, #3F51B5 0%, #3F51B5 24%);
  background: linear-gradient(to bottom, #3F51B5 0%, #3F51B5 24%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$mpk-gradient-primary-1", endColorstr="$mpk-gradient-primary-2",GradientType=0 );
}
.mpk-bg-slice .parallelogram-3 {
  position: absolute;
  top: 0;
  left: 4%;
  opacity: 0.5;
  width: 60%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #3F51B5;
  background: -moz-linear-gradient(top, #3F51B5 0%, #3F51B5 52%);
  background: -webkit-linear-gradient(top, #3F51B5 0%, #3F51B5 52%);
  background: linear-gradient(to bottom, #3F51B5 0%, #3F51B5 52%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$mpk-gradient-primary-1", endColorstr="$mpk-gradient-primary-2",GradientType=0 );
}
.mpk-bg-slice .parallelogram-4 {
  position: absolute;
  top: 0;
  right: 4%;
  opacity: 0.4;
  width: 40%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #3F51B5;
  background: -moz-linear-gradient(top, #3F51B5 0%, #3F51B5 50%);
  background: -webkit-linear-gradient(top, #3F51B5 0%, #3F51B5 50%);
  background: linear-gradient(to bottom, #3F51B5 0%, #3F51B5 50%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$mpk-gradient-primary-2", endColorstr="$mpk-gradient-primary-1",GradientType=0 );
}
.mpk-bg-slice .parallelogram-5 {
  position: absolute;
  top: 0;
  right: 0%;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #3F51B5;
  background: -moz-linear-gradient(top, #3F51B5 0%, #3F51B5 52%);
  background: -webkit-linear-gradient(top, #3F51B5 0%, #3F51B5 52%);
  background: linear-gradient(to bottom, #3F51B5 0%, #3F51B5 52%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$mpk-gradient-primary-2", endColorstr="$mpk-gradient-primary-1",GradientType=0 );
}