.mpk-image-thumbnail .image-container {
  width: 72px;
  height: 72px;
  position: relative;
}
.mpk-image-thumbnail .image-container.selected {
  border: 2px solid #3F51B5;
}
.mpk-image-thumbnail .image-container .img {
  width: 100%;
  height: 100%;
  background: #d6d0c9;
  background-size: cover !important;
}
.mpk-image-thumbnail .image-container .md-icon {
  position: absolute;
  right: 4px;
  font-size: 14px;
  top: 4px;
  cursor: pointer;
  color: white;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
.mpk-image-thumbnail .image-container .default-img .md-icon {
  position: relative;
  cursor: default;
  top: unset;
  right: unset;
  text-shadow: unset;
  font-size: 48px;
}