.mpk-notification .badge {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 24px;
  margin: 0 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.48);
  border: thin solid rgba(0, 0, 0, 0.064);
}
.mpk-notification .badge.active {
  background-color: #c62828;
  color: white;
  cursor: pointer;
}