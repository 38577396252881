$primary: rgb(24, 144, 255);
$error: red;
$gray: #AAA;

.crontab-input {
  margin: auto;
  .explanation {
    text-align: center;
    margin: 0px;
    font-size: 40px;
    color: $primary;
    span {
      background: transparent;
      color: inherit;
      &.active{
        text-decoration: underline;
      }
    }
  }

  .next {
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    font-size: .9em;
    opacity: .6;
    margin-bottom: 6px;
  }

  .cron-input {

    font-size: 30px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid $primary;
    outline: none;
    &.error {
      border: 1px solid $error;
    }
  }

  .parts {
    margin-top: 3px;
    .part {
      display: inline-block;
      width: 100px;
      text-align: center;
      &.selected {
        font-weight: bold;
        color: $primary;
      }
    }
  }

  .allowed-values {
    margin-top: 12px;
    .value {
      display: flex;
      width: 400px;
      margin: auto;
      .value, .key {
        border-bottom: 1px dashed $gray;
        padding: 4px;
      }
      .key {
        flex: 0;
        flex-basis: 100px;
        text-align: right;
        font-weight: bold;
        padding-right: 20px;
      }
      .value {
        flex: 1;
      }
    }
  }
  text-align: center;
}
