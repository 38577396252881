.ds-text {
    font-size: 12px;
    margin-bottom: 0px !important;
    padding: 5px;
}

.md-btn--tab-overflow-right {
    color: white !important;
    margin-top: 5px;
}

.md-btn--tab-overflow-left {
    color: white !important;
    margin-top: 5px;
}