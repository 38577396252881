.mpk-footbar {
  background-color: #222;
  color: rgba(255, 255, 255, 0.92);
  padding: 16px;
}
.mpk-footbar button {
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {
  .mpk-footbar {
    padding-top: 32px;
  }
  .mpk-footbar > div:last-child > div:first-child {
    display: none;
  }
}