@import 'globals';
@import '~react-mpk/styles/mpk';

$max-media-width:1024px;
$max-side-width:240px;

// .ag-root-wrapper.ag-layout-normal .md-btn--icon {
//   height: 20px;
// }

.ag-header-cell {
  background: #F5F7F7;
}

.actions-button-cell {
  overflow: visible;
}

.ag-theme-balham .ag-cell {
  line-height: 30px !important;
}

.md-data-table {
  .md-table-row tr, .md-table-row td {
    border: thin solid rgba(0, 0, 0, 0.052) !important;
    padding: 0 5px;
  }
}

.md-dialog-container.md-overlay {
  z-index: 50 !important;
}

.md-table-header .md-table-checkbox .md-icon {
  margin-top: 5px !important;
  margin-left: 5px !important;
  font-size: 12px !important;
}

.md-table-body .md-table-checkbox .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body .md-table-checkbox .md-btn--icon {
  padding: 12px !important;
  height: 0px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle > .md-icon > .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle > .md-icon {
  margin-top: -5px !important;
  font-size: 12px !important;
}

.md-table-body #mpk-table-action-item > #mpk-table-action-item-toggle.md-btn--icon {
  padding: 12px !important;
  height: 0px !important;
}


.md-data-table--plain .md-table-column--plain {
  font-size: 12px !important;
}

.md-data-table--plain .md-table-column--plain {
  font-size: 12px !important;
  height: 20px !important;
}

@media screen and (min-width: 1025px) {
  .md-table-body .md-selection-control-container {
      height: 10px !important;
      margin-top: -20px !important;
  }
}

window, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

.srd-diagram {
  width: 100%;
  min-height: 400px;
}

.mpk-bluepring-bg {
  background: url('./assets/bluepring-bg.jpg')
}

#mpk-inner-tbl > div {
  height: 100%;
}

.md-table-body-setting .md-grid {
  margin: 0 !important;
}

.center-container{
  width: $max-media-width;
  margin:0 auto;
}

.side-container{
  width: $max-side-width;
}

.text-editor{
  border:thin solid $mpk-border-color-dark;
  padding:0 $mpk-padding-N;
}

.mpk-tab-clear .md-tab--inactive {
  color: rgba(0, 0, 0, 0.72) !important;
}

.mpk-tab-flex {
  .md-tabs-content {
    flex: 1;

    .react-swipeable-view-container, .react-swipeable-view-container > div {
      height: 100% !important;
    }
  }
}

.md-dark-theme {
  .md-text {
    color: rgba(255, 255, 255, 0.87);
  }
  .md-text--secondary {
    color: rgba(255, 255, 255, 0.54);
  }
}

.form .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-menu--select-field .md-paper {
  width: inherit !important;
  height: inherit !important;
}

.md-list--menu-contained.md-paper{
  width: 100% !important;
}

.md-text-field-container {
  .md-btn--icon {
    position: absolute !important;
    height: 20px;
    width: 20px;
    color: inherit;
    top: 30px;
  }
}

.mpk-expansion-no-padding .md-panel-content {
  padding: 0 0px 16px;
}

.module-menu {
  position: absolute !important;
  top: -20px;
  left: 170px;
  z-index: 2;
  background: white !important;
}

.DayPicker-NavBar {
  z-index: 1000;
}

.mpk-tab-white {
  .md-tab {
    color: rgba(0, 0, 0, 0.72);
  }
  .md-tab--inactive {
    color: rgba(0, 0, 0, 0.5);
  }
}

.mpk-progress {
  width: 100%;
}

@media screen and (max-width: $max-media-width){
  .center-container{
    width: 100%;
    padding:0 $mpk-padding-N
  }
}

@media screen and (min-width: 1025px) {
  .md-tab {
    min-width: 72px !important;
  }
}

.ebillingCardTable > div {
  height: 550px;
}
