.mpk-text-editor {
  margin: 16px 0;
}
.mpk-text-editor label {
  margin-bottom: 8px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.48);
  font-weight: 600;
}
.mpk-text-editor .error-info {
  margin-top: 4px;
  color: #c62828;
  font-size: 12px;
}
.mpk-text-editor .ql-container.ql-snow {
  background: transparent;
  border: none;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.mpk-text-editor .ql-toolbar.ql-snow {
  border: none;
}