.mpk-status-info {
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  border: 2px solid #1565c0;
  margin-bottom: 8px;
  background: white;
  z-index: 10;
}
.mpk-status-info .md-icon {
  margin-right: 16px;
  color: #1565c0;
}
.mpk-status-info.error {
  color: #c62828;
  border-color: #c62828;
}
.mpk-status-info.error .md-icon {
  color: #c62828;
}
.mpk-status-info.success {
  color: #1565c0;
  border-color: #1565c0;
}
.mpk-status-info.success .md-icon {
  color: #1565c0;
}