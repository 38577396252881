.mpk-general-info {
  background-color: #263238;
  padding: 48px;
  color: rgba(255, 255, 255, 0.92);
  position: relative;
}
.mpk-general-info .link {
  padding-right: 48px;
}
.mpk-general-info .logo {
  position: relative;
}
.mpk-general-info .logo .mpk-logo {
  height: 48px;
}
.mpk-general-info .logo:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 160px;
  background: rgba(255, 255, 255, 0.1);
  margin-left: 8px;
  -moz-transform: rotate(12deg);
  -webkit-transform: rotate(12deg);
  transform: rotate(12deg);
}

@media screen and (max-width: 960px) {
  .mpk-general-info .logo:after {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .mpk-general-info .link {
    padding-bottom: 24px;
    border-bottom: thin solid rgba(255, 255, 255, 0.1);
  }
  .mpk-general-info .mpk-send-email {
    padding-top: 24px;
  }
}