.mpk-address {
  margin: 0 48px;
}
.mpk-address.light {
  color: rgba(255, 255, 255, 0.92);
}
.mpk-address.light .group .body, .mpk-address.light .group .value {
  color: rgba(255, 255, 255, 0.54) !important;
}
.mpk-address .group {
  margin-bottom: 16px;
}
.mpk-address .group .header {
  font-weight: 600;
  font-size: 16px;
}
.mpk-address .group .contact {
  margin-top: 8px;
}
.mpk-address .group .contact .label {
  font-size: 12px;
}

@media screen and (max-width: 960px) {
  .mpk-address {
    width: 100%;
    margin: 16px 0;
  }
  .mpk-address .group {
    margin-right: 8px;
  }
}