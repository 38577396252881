.container-paper .container {
  width: auto;
  margin: 0 auto;
  margin-top: 48px;
}
.container-paper .container .brand {
  text-align: center;
  margin-bottom: 32px;
}
.container-paper .container .brand .mpk-app-logo {
  height: 64px;
  justify-content: center !important;
}
.container-paper .container .md-paper {
  padding: 48px;
  margin: 0 auto;
  width: 400px;
  position: relative;
}
.container-paper .container .md-paper .md-progress--linear {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  margin: 0 !important;
}
.container-paper .container .header {
  font-size: 18px;
  margin-bottom: 16px;
}
.container-paper .container .header h2, .container-paper .container .header p {
  text-align: center;
}
.container-paper .container .header p {
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.72);
}
.container-paper .container .footer {
  margin-top: 24px;
}
.container-paper .container .action {
  margin-top: 24px;
}
.container-paper .container .action > button.md-btn {
  width: 100%;
}
.container-paper .container .action > div:first-child {
  text-align: center;
}
.container-paper .container .info {
  width: 400px;
  margin: 24px auto;
  z-index: 10;
  line-height: 12px;
}
.container-paper .container .info .pajakku b {
  color: #3F51B5;
}
.container-paper .container .info .pajakku .link > a {
  display: inline-block;
  padding: 0 4px;
  font-size: 10px;
  position: relative;
  outline: none;
  text-decoration: dotted;
  color: rgba(0, 0, 0, 0.72);
}
.container-paper .container .info .pajakku .link > a:after {
  content: ".";
  position: absolute;
  right: 0;
}
.container-paper .container .info .pajakku .link > a:first-child {
  padding-left: 0;
}
.container-paper .container .info .pajakku .link > a:last-child:after {
  display: none;
}
.container-paper .container .completed {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  border: thin solid #1565c0;
  border-radius: 48px;
}
.container-paper .container .completed .md-icon {
  font-size: 48px;
  color: #1565c0;
}
.container-paper.popup .md-paper {
  width: 540px;
}
.container-paper.popup .info {
  width: 540px;
}
.container-paper.popup .action button.md-btn {
  width: auto;
}
.container-paper.medium .md-paper {
  width: 640px;
}
.container-paper.medium .info {
  width: 640px;
}
.container-paper.medium .action button.md-btn {
  width: auto;
}
.container-paper.large .md-paper {
  width: 920px;
}
.container-paper.large .info {
  width: 920px;
}
.container-paper.large .action button.md-btn {
  width: auto;
}
.container-paper .validation {
  color: #c62828;
  font-weight: 500;
  font-size: 12px;
  padding: 8px 16px;
  border: 2px solid #c62828;
  margin-bottom: 8px;
}
.container-paper .validation .md-icon {
  margin-right: 16px;
  color: #c62828;
}

@media screen and (max-width: 600px) {
  .container-paper .container {
    padding: 0 16px;
  }
  .container-paper .container .md-paper {
    width: 100%;
    padding: 24px;
  }
  .container-paper .container .md-paper form * {
    margin-right: 0;
  }
  .container-paper .container .info {
    width: 100%;
  }
  .container-paper.flex-height {
    height: 100%;
    top: 0;
  }
  .container-paper.flex-height .container {
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
  .container-paper.flex-height .container .brand {
    text-align: center;
    padding-top: 24px;
    margin-bottom: 24px;
  }
  .container-paper.flex-height .container .cb {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .container-paper.flex-height .container .cb .header {
    flex: none;
    margin: 0;
  }
  .container-paper.flex-height .container .cb .children {
    flex: 1;
  }
  .container-paper.flex-height .container .cb .children form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .container-paper.flex-height .container .cb .children .action {
    margin: 0;
  }
  .container-paper.flex-height .container .cb .md-paper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .container-paper.flex-height .container .cb .footer {
    flex: none;
    margin: 0;
  }
  .container-paper.flex-height .container .cb .info {
    flex: 0;
  }
}