.account-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.account-container .panel {
  padding: 48px 0;
  width: 800px;
  position: relative;
  display: flex;
  background-color: white;
}
.account-container .panel * {
  flex: 1;
}
.account-container .panel .identity {
  padding: 48px;
  text-align: center;
}
.account-container .panel .identity img.mpk-logo {
  height: 80px;
}
.account-container .panel .identity img.ills {
  width: 180px;
  margin-top: 24px;
}
.account-container .panel .content {
  padding: 48px;
  border-left: thin solid #f2f2f2;
}
.account-container .panel .content .title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
}
.account-container .panel .content .opt {
  margin-top: 16px;
  text-align: center;
}
.account-container .panel .content .opt .mpk-link {
  font-size: 12px;
}
.account-container .panel .action {
  right: 24px;
  top: 24px;
  position: absolute;
}
.account-container .footer {
  padding: 8px 0;
  width: 800px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.48);
}
.account-container .footer .link:after {
  content: "|";
  margin: 0 8px;
}
.account-container .footer .link:last-child:after {
  display: none;
}

.account {
  background: #e2e2e2;
  overflow-x: hidden;
  position: relative;
}
.account .bg {
  positon: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.account .bg .parallelogram-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.54;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #e2e2e2; /* Old browsers */
  background: -moz-linear-gradient(top, #e2e2e2 56%, #eee 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e2e2e2 56%, #eee 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e2e2e2 56%, #eee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$gradient-primary-1", endColorstr="$gradient-primary-2",GradientType=0 ); /* IE6-9 */
}
.account .bg .parallelogram-2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 1;
  -webkit-transform: skew(56deg);
  -moz-transform: skew(56deg);
  -o-transform: skew(56deg);
  background: #eee; /* Old browsers */
  background: -moz-linear-gradient(top, #e2e2e2 0%, #eee 24%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e2e2e2 0%, #eee 24%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e2e2e2 0%, #eee 24%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$gradient-primary-1", endColorstr="$gradient-primary-2",GradientType=0 ); /* IE6-9 */
}
.account .bg .parallelogram-3 {
  position: absolute;
  top: 0;
  left: 0%;
  opacity: 0.24;
  width: 18%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #eee; /* Old browsers */
  background: -moz-linear-gradient(top, #e2e2e2 0%, #eee 52%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e2e2e2 0%, #eee 52%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e2e2e2 0%, #eee 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$gradient-primary-1", endColorstr="$gradient-primary-2",GradientType=0 ); /* IE6-9 */
}
.account .bg .parallelogram-4 {
  position: absolute;
  top: 0;
  right: 30%;
  opacity: 0.32;
  width: 40%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #eee; /* Old browsers */
  background: -moz-linear-gradient(top, #eee 0%, #e2e2e2 52%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #eee 0%, #e2e2e2 52%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eee 0%, #e2e2e2 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$gradient-primary-2", endColorstr="$gradient-primary-1",GradientType=0 ); /* IE6-9 */
}
.account .bg .parallelogram-5 {
  position: absolute;
  top: 0;
  right: 5%;
  opacity: 0.4;
  width: 40%;
  height: 100%;
  -webkit-transform: skew(-12deg);
  -moz-transform: skew(-12deg);
  -o-transform: skew(-12deg);
  background: #eee; /* Old browsers */
  background: -moz-linear-gradient(top, #eee 0%, #e2e2e2 52%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #eee 0%, #e2e2e2 52%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eee 0%, #e2e2e2 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="$gradient-primary-2", endColorstr="$gradient-primary-1",GradientType=0 ); /* IE6-9 */
}
.account .account-main {
  position: absolute;
  width: 100%;
  height: 100%;
}
.account .account-header {
  width: 100%;
  height: auto;
}