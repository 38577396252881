.mpk-corner-ribbon {
  position: absolute;
  right: 0;
  top: 0;
  width: 92px;
  height: 92px;
  overflow: hidden;
  z-index: 1;
}
.mpk-corner-ribbon .wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.mpk-corner-ribbon .ribbon {
  font-size: 13px;
  color: #ddd;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  padding: 7px 0;
  left: 0px;
  top: 16px;
  width: 120px;
  background: #ff6e40;
  font-weight: bold;
}
.mpk-corner-ribbon .ribbon:before {
  left: 0;
}
.mpk-corner-ribbon .ribbon:after {
  right: 0;
}