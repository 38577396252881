.mpk-dialog-container {
  position: relative;
  color: rgba(0, 0, 0, 0.72);
}
.mpk-dialog-container .md-progress--linear {
  position: absolute;
  top: 0 !important;
  left: 0;
  margin: 0;
  background-color: #eee !important;
}

.mpk-dialog {
  position: relative;
  color: rgba(0, 0, 0, 0.72);
}
.mpk-dialog .md-progress--linear {
  position: absolute;
  top: 0 !important;
  left: 0;
  margin: 0;
  background-color: #eee !important;
}
.mpk-dialog .md-btn--dialog {
  padding: 8px 16px;
}
.mpk-dialog .error-block {
  padding: 0 24px;
}
.mpk-dialog .error-block > div {
  padding: 8px 8px;
  display: flex;
  align-items: center;
  color: #c62828;
  font-weight: 600;
  margin-bottom: 16px;
  border: 2px solid #c62828;
}
.mpk-dialog .error-block > div .md-icon {
  margin-right: 8px;
  color: #c62828;
}
.mpk-dialog .md-dialog-footer {
  background: #f7efe8;
  border-top: thin solid rgba(0, 0, 0, 0.064);
}
.mpk-dialog .md-dialog {
  min-width: 280px;
  width: auto;
}
.mpk-dialog.dialog-form > .md-paper {
  display: flex;
  flex-direction: column;
}
.mpk-dialog.dialog-form .md-dialog-content {
  flex: 1;
  overflow: auto;
}
.mpk-dialog.dialog-form .md-dialog-content .form {
  padding: 0 24px;
}
.mpk-dialog.dialog-form .md-dialog-content--padded {
  padding: 0;
}
.mpk-dialog.dialog-form.size-large .md-paper {
  width: 80%;
  height: 80%;
}
.mpk-dialog.dialog-form.size-medium .md-paper {
  width: 960px;
  height: 80%;
}
.mpk-dialog.dialog-form.size-normal .md-paper {
  width: 600px;
  height: 80%;
}
.mpk-dialog.dialog-form.size-small .md-paper {
  width: 480px;
  height: 80%;
}
.mpk-dialog.dialog-form.relative-content .md-paper {
  display: inline-block;
  height: auto;
}

.md-overlay {
  width: 100% !important;
}

@media screen and (max-width: 960px) {
  .mpk-dialog.size-medium .md-paper, .mpk-dialog.size-normal .md-paper {
    width: 100%;
    height: 100%;
  }
}