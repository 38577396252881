.mpk-section {
  padding-bottom: 16px;
}
.mpk-section .title {
  color: #3F51B5;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-bottom: 4px;
  font-size: 14px;
}
.mpk-section .title:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: #3F51B5;
}
.mpk-section .deck {
  font-size: 24px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.48);
  margin-top: 16px;
}
.mpk-section .body {
  color: rgba(0, 0, 0, 0.72);
  margin-top: 16px;
}
.mpk-section .footer {
  margin-top: 16px;
}
.mpk-section.dark .title {
  color: rgb(255, 255, 255);
}
.mpk-section.dark .body {
  color: rgba(255, 255, 255, 0.92);
  font-weight: 400;
}