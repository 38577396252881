@import '../../styles/var';

.mpk-sidebar-info{
  .panel{
    background-color: rgba(0,0,0,.048);
    background-blend-mode: darken;
    .header{
      height:40px;
      color:$mpk-primary-color;
      font-weight: 500;
      .md-icon{
        font-size: 16px;
        cursor: pointer;
        margin-left: 4px;
      }
    }
    @if $mpk-sidebar-theme == 'dark' {
      background-color: rgba(255,255,255,.04);
      background-blend-mode: overlay;
      color:$mpk-font-color-L2;
      .header{
        color:$mpk-color-alert;
      }
      .md-icon{
        color:$mpk-font-color-L2;
      }
    }
  }
  .dialog {
    color:$mpk-font-color-D2;
    .title {
      color: $mpk-color-warn;
    }
  }
}