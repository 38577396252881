.mpk-step {
  overflow: hidden;
}
.mpk-step .mpk-step-content {
  padding-top: 16px;
}
.mpk-step .mpk-step-content .actions {
  margin-top: 16px;
}
.mpk-step .mpk-step-item {
  flex: 1;
}
.mpk-step .mpk-step-item:last-child {
  flex: none;
}
.mpk-step .mpk-step-item:last-child .label:after {
  display: none;
}
.mpk-step .mpk-step-item.active .number {
  background: #3F51B5;
  color: white;
}
.mpk-step .mpk-step-item.active .label {
  font-weight: 600;
}
.mpk-step .mpk-step-item .number {
  width: 28px;
  height: 28px;
  font-weight: bold;
  border-radius: 50%;
  background: #fff9f6;
  color: #3F51B5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: thin solid #3F51B5;
}
.mpk-step .mpk-step-item .label {
  display: flex;
  flex: 1;
  align-items: center;
}
.mpk-step .mpk-step-item .label:after {
  content: "";
  flex: 1;
  height: 1px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 16px;
}