@import "../../styles/var";

.mpk-sidebar-footer{
  color:$mpk-font-color-D1;
  > div{
    border-top:thin solid $mpk-border-color-dark;
    padding: 18px 0;
  }
  .link{
    display: flex;
    div{
      &:after{
        content:"|";
        padding:0 6px;
      }
      &:last-child:after{
        display: none;
      }
    }
  }
  @if $mpk-sidebar-theme == 'dark' {
    color:$mpk-font-color-L2;
    > div {
      border-top: thin solid $mpk-border-color-white;
    }
  }
}