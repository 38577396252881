table-ssp {
  border-collapse: collapse;
  width: 100%;
  tr {
    margin: 0;
  }
  tr td {
    font-size: 15px;
    padding: 5px 10px;
    margin: 0;
    border: 1px solid black;
  }

  tr.fk-clear {
    border-right: 1px solid black;
    border-left: 1px solid black;
    td {
      border: none !important;
      padding: 0px 10px;
    }
  }

  td.center {
    text-align: center;
  }

  td.right {
    text-align: right;
  }
}