.mpk-search-field.border {
  border-radius: 2px;
  background: white;
  border: thin solid rgba(0, 0, 0, 0.08);
}
.mpk-search-field.border .md-divider {
  display: none;
}
.mpk-search-field.border input {
  margin-top: 0;
  padding: 0 16px;
}
.mpk-search-field.border button {
  height: 40px;
  min-width: 48px;
}
.mpk-search-field.as-panel {
  background: white;
}