.mpk-app-logo {
  display: flex;
  align-items: center;
}
.mpk-app-logo .picture-mark {
  height: 32px;
}
.mpk-app-logo .logo-type {
  height: 20px;
  margin-left: 8px;
}
.mpk-app-logo img {
  height: 100%;
}

@media screen and (max-width: 960px) {
  .mpk-app-logo .logo-type {
    display: none;
  }
}