.mpk-appbar {
  background: white;
  height: 64px;
  padding: 0 16px;
  z-index: 20;
  position: relative;
  border-bottom: thin solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.96);
}
.mpk-appbar .mpk-logo, .mpk-appbar .mpk-app-logo {
  cursor: pointer;
}
.mpk-appbar .mpk-logo.light, .mpk-appbar .mpk-app-logo.light {
  display: none;
}
.mpk-appbar .navs {
  padding: 8px;
}
.mpk-appbar .navs.lt-sm {
  display: none;
  margin-left: 16px;
}
.mpk-appbar .navs.lt-sm .nav-item {
  width: 100%;
  text-align: left;
}
.mpk-appbar .navs .nav-item {
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.48);
  text-transform: none;
}
.mpk-appbar .navs .nav-item.active {
  color: #3F51B5 !important;
}
.mpk-appbar .navs .nav-item.active span {
  font-weight: 500 !important;
}
.mpk-appbar.fixed {
  position: fixed;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .mpk-appbar .navs.gt-sm {
    display: none;
  }
  .mpk-appbar .navs.lt-sm {
    display: inline;
  }
}