.sgn-layout.column > .sgn-layout-element {
  width: 100%;
}
.sgn-layout.row > .sgn-layout-element {
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .sgn-layout.column.switch-md {
    flex-direction: row !important;
  }
  .sgn-layout.row.switch-md {
    flex-direction: column !important;
  }
  .sgn-layout.row.switch-md > .sgn-layout-element {
    width: 100% !important;
  }
}
@media screen and (max-width: 960px) {
  .sgn-layout.column.switch-sm {
    flex-direction: row !important;
  }
  .sgn-layout.column.switch-sm.reverse {
    flex-direction: row-reverse !important;
  }
  .sgn-layout.row.switch-sm {
    flex-direction: column !important;
  }
  .sgn-layout.row.switch-sm.reverse {
    flex-direction: column-reverse !important;
  }
  .sgn-layout.row.switch-sm > .sgn-layout-element {
    width: 100% !important;
  }
}