.mpk-sidabar-menu {
  color: rgba(0, 0, 0, 0.72);
  background: inherit;
  padding: 0 8px;
  z-index: 0;
}
.mpk-sidabar-menu * {
  box-sizing: border-box;
}
.mpk-sidabar-menu .group {
  background: inherit;
  border-bottom: thin solid rgba(0, 0, 0, 0.064);
}
.mpk-sidabar-menu .group .menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.72);
  text-transform: none;
  position: relative;
  text-align: left;
  color: rgba(255, 255, 255, 0.92);
  border-bottom: thin solid rgba(255, 255, 255, 0.1);
}
.mpk-sidabar-menu .group .menu-item.active .md-icon {
  color: #3F51B5;
  position: relative;
}
.mpk-sidabar-menu .group .menu-item .md-icon {
  margin-right: 12px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.24);
}
.mpk-sidabar-menu .group .menu-item .md-icon {
  color: rgba(255, 255, 255, 0.24);
}
.mpk-sidabar-menu .group:last-child {
  border-bottom: none;
}
.mpk-sidabar-menu .group .subheader {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.72);
  background-color: inherit;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin: 0;
  padding-right: 25px;
  z-index: 1;
  color: rgba(255, 255, 255, 0.92);
}
.mpk-sidabar-menu .group .collapse-icon {
  display: none;
}
.mpk-sidabar-menu .group.collapsible {
  cursor: pointer;
}
.mpk-sidabar-menu .group.collapsible .collapse-icon {
  display: block;
  position: absolute;
  right: 12px;
  color: inherit;
  font-size: 16px;
}