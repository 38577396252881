.mpk-container-form .container {
  width: auto;
  margin: 0 auto;
}
.mpk-container-form .container .cb > .md-paper {
  margin: 0 auto;
  width: 400px;
  position: relative;
}
.mpk-container-form .container .cb > .md-paper .md-progress--linear {
  background: rgba(0, 0, 0, 0.25) !important;
  position: sticky;
  top: 0;
  width: 100%;
  height: 2px;
  left: 0;
  margin: 0 !important;
}
.mpk-container-form .container .header {
  text-align: center;
  font-size: 18px;
  margin-bottom: 16px;
}
.mpk-container-form .container .header p {
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.72);
}
.mpk-container-form .container .footer {
  margin-top: 24px;
}
.mpk-container-form .container .action {
  margin-top: 24px;
}
.mpk-container-form .container .action > button.md-btn {
  width: 100%;
  min-width: unset;
}
.mpk-container-form .container .action > div:first-child {
  text-align: center;
}
.mpk-container-form .container .info {
  width: 400px;
  margin: 24px auto;
  z-index: 10;
  line-height: 12px;
}
.mpk-container-form .container .info .pajakku b {
  color: #3F51B5;
}
.mpk-container-form .container .info .pajakku .link > a {
  display: inline-block;
  padding: 0 4px;
  font-size: 10px;
  position: relative;
  outline: none;
  text-decoration: dotted;
  color: rgba(0, 0, 0, 0.72);
}
.mpk-container-form .container .info .pajakku .link > a:after {
  content: ".";
  position: absolute;
  right: 0;
}
.mpk-container-form .container .info .pajakku .link > a:first-child {
  padding-left: 0;
}
.mpk-container-form .container .info .pajakku .link > a:last-child:after {
  display: none;
}
.mpk-container-form.popup .cb > .md-paper {
  width: 540px;
}
.mpk-container-form.popup .cb .info {
  width: 540px;
}
.mpk-container-form.popup .cb .action button.md-btn {
  width: auto;
}
.mpk-container-form.medium .cb > .md-paper {
  width: 640px;
}
.mpk-container-form.medium .cb .info {
  width: 640px;
}
.mpk-container-form.medium .cb .action button.md-btn {
  width: auto;
}
.mpk-container-form.large .cb > .md-paper {
  width: 920px;
}
.mpk-container-form.large .cb .info {
  width: 920px;
}
.mpk-container-form.large .cb .action button.md-btn {
  width: auto;
}

@media screen and (max-width: 600px) {
  .container-paper .container {
    padding: 0 16px;
  }
  .container-paper .container .md-paper {
    width: 100%;
    padding: 24px;
  }
  .container-paper .container .md-paper form * {
    margin-right: 0;
  }
  .container-paper .container .info {
    width: 100%;
  }
  .container-paper.flex-height {
    height: 100%;
    top: 0;
  }
  .container-paper.flex-height .container {
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
  .container-paper.flex-height .container .brand {
    text-align: left;
    padding-top: 24px;
    margin-bottom: 24px;
  }
  .container-paper.flex-height .container .brand .mpk-logo {
    height: 36px;
  }
  .container-paper.flex-height .container .cb {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .container-paper.flex-height .container .cb .header {
    flex: none;
    margin: 0;
  }
  .container-paper.flex-height .container .cb .children {
    flex: 1;
  }
  .container-paper.flex-height .container .cb .children form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .container-paper.flex-height .container .cb .children .action {
    margin: 0;
  }
  .container-paper.flex-height .container .cb .md-paper {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .container-paper.flex-height .container .cb .footer {
    flex: none;
    margin: 0;
  }
  .container-paper.flex-height .container .cb .info {
    flex: 0;
  }
}