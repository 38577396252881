.mpk-activation-key .header {
  margin-bottom: 16px;
}
.mpk-activation-key .group {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.mpk-activation-key .group label {
  color: rgba(255, 255, 255, 0.54);
}
.mpk-activation-key .group input, .mpk-activation-key .group textarea {
  padding: 8px;
  outline: none;
  background: rgba(0, 0, 0, 0) !important;
  border: thin solid black;
  color: black;
  font-size: 14px;
}
.mpk-activation-key .group input:focus, .mpk-activation-key .group textarea:focus {
  border: thin solid #3F51B5;
}
.mpk-activation-key .group textarea {
  resize: none;
  height: 85px;
}