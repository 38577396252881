.mpk-section-header {
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: #3F51B5;
  position: relative;
}
.mpk-section-header .center-container {
  text-align: center;
  text-align: center;
  max-width: 960px;
  z-index: 2;
}
.mpk-section-header .center-container > * {
  position: relative;
}
.mpk-section-header .title {
  color: white;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-bottom: 4px;
  font-size: 14px;
}
.mpk-section-header .title:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: white;
}
.mpk-section-header .body {
  font-size: 20px;
  margin-top: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.92);
}
.mpk-section-header .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 0;
}