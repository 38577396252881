.mpk-send-email .header {
  margin-bottom: 16px;
}
.mpk-send-email .group {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.mpk-send-email .group label {
  color: rgba(255, 255, 255, 0.54);
}
.mpk-send-email .group input, .mpk-send-email .group textarea {
  padding: 8px;
  outline: none;
  background: rgba(0, 0, 0, 0.08) !important;
  border: thin solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.92);
  font-size: 14px;
}
.mpk-send-email .group input:focus, .mpk-send-email .group textarea:focus {
  border: thin solid #3F51B5;
}
.mpk-send-email .group textarea {
  resize: none;
  height: 120px;
}