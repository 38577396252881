.mpk-companies h1 {
  font-weight: 600;
  margin-top: 8px;
}
.mpk-companies .company-center-container {
  margin: 0 auto;
  padding: 0 48px;
}
.mpk-companies .lb {
  max-width: 240px;
  top: 8px;
}
.mpk-companies .company-card {
  width: 240px;
  display: inline-table;
}
.mpk-companies .company-card .company-card-logo {
  width: 240px;
  height: 240px;
  border-radius: 0;
  background: #836464;
}
.mpk-companies .company-card .company-card-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mpk-companies .company-card .company-card-logo .md-icon {
  font-size: 92px;
  color: #f7efe8;
}
.mpk-companies .company-card .company-card-info {
  border-top: thin solid #f7efe8;
}
.mpk-companies .company-card .company-card-info .md-icon {
  color: #bbb;
  font-size: 16px;
}