.no-mb {
  margin-bottom: 0px;
}

.no-border {
  table {
    tr td {
      border: 0px !important
    }
  }
}

.rfm-preview {
    .fk-header {
      text-align: center;
      font-weight: bold;
    }
  
    .table-border {
      table {
        border-collapse: collapse;
        width: 100%;
        tr {
          margin: 0;
        }
        tr td {
          font-size: 15px;
          padding: 5px 10px;
          margin: 0;
          border: 1px solid black;
        }
    
        tr.fk-clear {
          border-right: 1px solid black;
          border-left: 1px solid black;
          td {
            border: none !important;
            padding: 0px 10px;
          }
        }
    
        td.center {
          text-align: center;
        }
    
        td.right {
          text-align: right;
        }
      }
    }
  
    .ket-tambahan {
      padding: 10px;
      width: 200px;
      float: left;
      border: 1px solid black;
      text-align: center;
      font-size: 15px;
    }
  
    .penanda-tangan {
      width: 200px;
      float: right;
      text-align: center;
      font-size: 15px;
    }
  
    .penanda-tangan2 {
      width: 200px;
      float: left;
      text-align: center;
      font-size: 15px;
    }
  }
