.mpk-app-logo-static {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpk-app-logo-static .picture-mark {
  height: 100%;
}
.mpk-app-logo-static .logo-type-container {
  height: 64%;
  margin-left: 8px;
}
.mpk-app-logo-static .logo-type-container img {
  height: 100%;
}