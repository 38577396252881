.mpk-treeview {
  width: 100%;
}
.mpk-treeview ul {
  width: 100%;
  background-color: transparent !important;
  font-family: "Roboto", serif !important;
  color: rgba(0, 0, 0, 0.72) !important;
}
.mpk-treeview ul li div {
  color: rgba(0, 0, 0, 0.72) !important;
  background: transparent !important;
}