.mpk-sidebar-footer {
  color: rgba(0, 0, 0, 0.96);
  color: rgba(255, 255, 255, 0.92);
}
.mpk-sidebar-footer > div {
  border-top: thin solid rgba(0, 0, 0, 0.064);
  padding: 18px 0;
}
.mpk-sidebar-footer .link {
  display: flex;
}
.mpk-sidebar-footer .link div:after {
  content: "|";
  padding: 0 6px;
}
.mpk-sidebar-footer .link div:last-child:after {
  display: none;
}
.mpk-sidebar-footer > div {
  border-top: thin solid rgba(255, 255, 255, 0.1);
}