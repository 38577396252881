.mpk-card-article-headline {
  position: relative;
  margin-bottom: 16px;
}
.mpk-card-article-headline img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.mpk-card-article-headline .overlay {
  position: absolute;
  bottom: 0;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.72);
  width: 100%;
}
.mpk-card-article-headline .overlay .title {
  font-size: 20px;
  color: rgb(255, 255, 255);
}
.mpk-card-article-headline .overlay .info {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.54);
}