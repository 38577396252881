@import '../../styles/var';

.mpk-mitra-djp{
  background: white;
  border-radius: 4px;
  padding: 4px;
  &.light{
    color:$mpk-font-color-L2 !important;
  }
  .body{
    line-height: 1;
    border-left: thin dotted rgba(0,0,0,.52) !important;
    * {
      color: $mpk-font-color-D3 !important;
    }
    .djp{
      letter-spacing: 1px;
    }
  }
}