.support-mpk {
  position: absolute;
  z-index: 1000;
  bottom: 24px;
  right: 24px;
}
.support-mpk .md-tile-content--left-icon {
  padding-left: 16px !important;
}
.support-mpk .menu-items {
  min-width: 240px;
  position: absolute;
  right: 56px;
  top: -120px;
  padding: 8px;
}
.support-mpk .menu-items .md-tile-addon--icon {
  height: unset;
}
.support-mpk .menu-items .info {
  font-size: 12px;
  color: #aaa;
  padding: 8px 16px;
}
.support-mpk .menu-items .header {
  color: #2883e5;
  font-weight: bold;
  padding: 8px 16px 0 16px;
  display: flex;
  align-items: center;
}
.support-mpk .menu-items .header > .md-icon {
  color: inherit;
  margin-right: 10px;
}